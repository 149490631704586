@import 'Containers/Analytics/index.scss';

.ComparisonsComponent {
  min-height: 500px;
  .legend-container {
    display: flex;
    margin: 8px;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    width: 50%;
    margin-left: auto;
    padding: 8px;
    padding-left: 15px;
    .legend-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .legend {
        display: flex;
        align-items: center;
        grid-gap: 3px;
        margin-bottom: 10px;
        margin-right: 12px;
        &:first {
          margin-left: 0px;
        }
        .label {
          border: 2px solid rgba(var(--white), 0.8);
          width: 15px;
          height: 15px;
          border-radius: 20px;
        }
        .name {
          font-weight: 700;
          font-size: 12px;
          max-width: 110px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: default;
        }
      }
    }
  }

  .charts-wrapper {
    // height: 800px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    .box {
      @include box;
    }

    .bar,
    .pie {
      height: 300px;
      margin: 10px 5px;
    }
  }
}
